<template>
  <v-container fluid class="pa-0 audit-logs">
    <v-toolbar height="56">
      <v-toolbar-title>Integrations</v-toolbar-title>
    </v-toolbar>

    <v-container class="d-flex flex-column align-center">
      <template v-for="opt in optionsMeta">
        <v-card :key="opt.key" class="my-4">
          <v-card-title>{{ opt.title }}</v-card-title>
          <v-card-text>
            <v-expansion-panels flat>
              <v-expansion-panel v-for="c in opt.children" :key="c.key">
                <v-expansion-panel-header class="px-0">
                  <v-avatar tile size="32" class="flex-grow-0 mr-4">
                    <img :src="require('./' + c.icon)" :alt="c.title + ' icon'">
                  </v-avatar>
                  <div class="flex-fill">{{ c.title }}</div>
                  <template #actions>
                    <template v-if="!loaded">
                      <v-progress-circular indeterminate/>
                    </template>
                    <template v-else>
                      <v-chip
                          :color="c.enabled ? 'success' : ''"
                          depressed>
                        {{ c.enabled ? 'Enabled' : 'Disabled' }}
                      </v-chip>
                    </template>
                    <v-icon v-if="c.expandable">$expand</v-icon>
                    <div v-else class="icon-placeholder"/>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="c.expandable">
                  <component :is="c.component" :enabled="c.enabled"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {get as _get} from 'lodash';

export default {
  name: 'Integrations',
  data() {
    return {
      options: [
        {
          title: 'Access Control',
          key: 'accessControl',
          children: [
            {
              title: 'Brivo',
              icon: 'brivo.png',
              key: 'brivo',
              component() {
                // eslint-disable-next-line max-len
                return import(/* webpackChunkName: "integrations-brivo" */ '@/views/admin/integrations/IntegrationBrivo.vue');
              }
            }
          ]
        },
        {
          title: 'Room Bookings',
          key: 'roomBooking',
          children: [
            {
              title: 'Microsoft 365/Outlook',
              icon: 'microsoft.png',
              key: 'm365',
              component() {
                // eslint-disable-next-line max-len
                return import(/* webpackChunkName: "integrations-m365" */ '@/views/admin/integrations/IntegrationM365.vue');
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState('views/admin/integrations', ['integrations']),
    ...mapGetters('views/admin/integrations', ['loaded']),
    optionsMeta() {
      // a copy of options with `enabled` & `expandable` properties
      const opts = [];
      for (const o of this.options) {
        opts.push({
          ...o,
          children: o.children.map(c => {
            return {
              ...c,
              expandable: typeof c.component === 'function',
              enabled: this.get(`${o.key}.${c.key}`)
            };
          })
        });
      }
      return opts;
    }
  },
  mounted() {
    this.bind();
  },
  beforeDestroy() {
    this.unbind();
  },
  methods: {
    ...mapActions('views/admin/integrations', ['bind', 'unbind']),
    get(path) {
      return Boolean(_get(this.integrations, path));
    }
  }
};
</script>

<style scoped>
.audit-logs > .v-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  z-index: 6;
}
.v-card {
  width: 100%;
  max-width: 800px;
}
.icon-placeholder {
  width: 24px;
}
</style>
