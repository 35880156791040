<template>
  <v-card flat>
    <v-card-text>
      <template v-if="enabled">
        Integration is enabled. To update your Brivo API key, use the form below.
      </template>
      <template v-else>
        Integration is not enabled. To enable integration you must enter your Brivo API key, and a Brivo Admin must
        approve the Kahu app.

        <ol class="my-2">
          <li>
            To start, enter your API key, and submit.
          </li>
          <li>
            Then, follow the link to approve the app, this will take you to Brivo and require a Brivo admin login.
          </li>
        </ol>
      </template>

      <v-text-field
          type="password"
          label="Brivo API Key"
          v-model="apiKey"
          clearable/>
      <div>
        <v-icon color="warning">mdi-alert</v-icon> An invalid API key will stop any integration from working.
        If you need help, please contact <a href="https://help.kahu.work/overview/">support</a>.
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="!valid" :loading="loading" @click="submitApiKey">
        Submit API Key
      </v-btn>
      <v-btn color="secondary" target="_blank" :href="fullUrl" v-if="!enabled">
        <v-icon>mdi-open-in-new</v-icon>
        Approve App
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

const clientId = process.env.VUE_APP_BRIVO_CLIENT_ID || 'c50446f3-eb9a-4e12-9647-62383524be93';
const brivoUrl = 'https://auth.brivo.com/oauth/authorize?response_type=code&client_id=' + clientId;

export default {
  name: 'IntegrationBrivo',
  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiKey: null,
      loading: false
    };
  },
  computed: {
    ...mapState('ns', {
      nsDoc: 'doc'
    }),
    ...mapGetters('views/admin/integrations', ['thirdPartyCollection']),
    fullUrl() {
      const state = {};
      state.ns = this.nsDoc.id;
      if (this.nsDoc.title) {
        state.title = this.nsDoc.title;
      }
      const encoded = encodeURIComponent(JSON.stringify(state));
      return brivoUrl + `&state=${encoded}`;
    },
    valid() {
      return this.apiKey && this.apiKey !== '';
    }
  },
  methods: {
    async submitApiKey() {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      try {
        await this.thirdPartyCollection.doc('brivo').set({
          apiKey: this.apiKey
        }, {merge: true});
        this.apiKey = null;
        this.$notify.showSuccess('api key updated');
      } catch (e) {
        this.$logger.error('submitApiKey', e);
        this.$notify.showError(`error submitting API key: ${e.message}`);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>

</style>
