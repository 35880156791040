<template>
  <v-card flat>
    <v-card-text>
      <template v-if="enabled">
        Integration is enabled.
      </template>
      <template v-else>
        Integration is not enabled. To enable integration a Azure admin must approve the Kahu app,
        follow the link below.
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="secondary" target="_blank" :href="fullUrl" v-if="!enabled">
        <v-icon>mdi-open-in-new</v-icon>
        Approve App
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from 'vuex';
// eslint-disable-next-line max-len
const m365Url = 'https://login.microsoftonline.com/common/adminconsent?client_id=7c6ee885-2690-4510-9bf5-804747eb5db4&state=<state>&redirect_uri=https://europe-west1-kahu-work-prod.cloudfunctions.net/integrations-m365-fromM365-processAdminConsent';

export default {
  name: 'IntegrationM365',
  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('ns', {
      nsDoc: 'doc'
    }),
    fullUrl() {
      const state = {};
      state.ns = this.nsDoc.id;
      if (this.nsDoc.title) {
        state.title = this.nsDoc.title;
      }
      const encoded = encodeURIComponent(JSON.stringify(state));
      return m365Url.replace('<state>', encoded);
    }
  }
};
</script>

<style scoped>

</style>
